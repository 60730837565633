export const ColorPicker = [
  {
    colorCode: 'rgba(255, 255, 255, 1)',
    colorBgCode: 'rgba(255, 255, 255, 0.6)',
    colorName: 'white',
    textColor: 'rgba(102, 114, 128, 1)',
  },
  {
    colorCode: 'rgba(17, 133, 242, 1)',
    colorBgCode: 'rgba(219, 234, 254, 0.6)',
    colorName: 'blue',
    textColor: 'rgba(59, 130, 246, 1)',
  },
  {
    colorCode: 'rgba(0, 255, 255, 1)',
    colorBgCode: 'rgba(172, 255, 252, 0.6)',
    colorName: 'cyan',
    textColor: 'rgba(0, 139, 139, 1)',
  },
  {
    colorCode: 'rgba(252, 165, 165, 1)',
    colorBgCode: 'rgba(254, 205, 211, 0.6)',
    colorName: 'red',
    textColor: 'rgba(239, 68, 68, 1)',
  },
  {
    colorCode: 'rgba(74, 222, 128, 1)',
    colorBgCode: 'rgba(187, 247, 208, 0.6)',
    colorName: 'green',
    textColor: 'rgba(22, 163, 74, 1)',
  },
  {
    colorCode: 'rgba(253, 224, 71, 1)',
    colorBgCode: 'rgba(253, 230, 138, 0.6)',
    colorName: 'yellow',
    textColor: 'rgba(202, 138, 4, 1)',
  },
  {
    colorCode: 'rgba(253, 186, 116, 1)',
    colorBgCode: 'rgba(254, 215, 170, 0.6)',
    colorName: 'orange',
    textColor: 'rgba(249, 115, 22, 1)',
  },
  {
    colorCode: 'rgba(123, 44, 191, 1)',
    colorBgCode: 'rgba(203, 195, 227, 0.6)',
    colorName: 'purple',
    textColor: 'rgba(48, 25, 52, 1)',
  },
];
