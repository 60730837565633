import { Tooltip } from '@mui/material';
import TooltipContent from './TooltipContent';
import ChipContent from './ChipContent';

const EventChip = ({ eventInfo }) => {
  const setBackgroundOpacity = (rgba, opacity) => {
    return rgba.replace(/[\d\.]+\)$/g, `${opacity})`);
  };

  const handleMouseEnter = (eventInfo) => (e) => {
    const eventElement = e.currentTarget.closest('.fc-h-event');
    eventElement.style.border = `2px solid ${eventInfo.textColor}`;
    eventElement.style.backgroundColor = setBackgroundOpacity(eventInfo.backgroundColor, 0.9);
  };

  const handleMouseLeave = (eventInfo) => (e) => {
    const eventElement = e.currentTarget.closest('.fc-h-event');
    eventElement.style.backgroundColor = setBackgroundOpacity(eventInfo.backgroundColor, 0.6);
    eventElement.style.border = `2px solid transparent`;
  };

  return (
    <div>
      <Tooltip
        placement="top"
        title={<TooltipContent eventInfo={eventInfo} />}
        slotProps={{
          tooltip: {
            sx: {
              color: '#514E6A',
              backgroundColor: '#ffff',
              boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            },
          },
        }}
      >
        <div
          className="font-bold truncate my-auto px-1"
          onMouseEnter={handleMouseEnter(eventInfo)}
          onMouseLeave={handleMouseLeave(eventInfo)}
        >
          <ChipContent eventInfo={eventInfo} />
        </div>
      </Tooltip>
    </div>
  );
};

export default EventChip;
